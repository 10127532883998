import Vue from 'vue'
import VueRouter from 'vue-router'
const Index = () => import('@/views/index')
// const Layout = () => import('@/views/Layout')
// const About = () => import('@/views/about')

Vue.use(VueRouter)

const routes = [
      {
        path: '/',
        redirect: {
          path: '/Index'
        }
      },
	  {
	      path: '/Index',
	      name: 'index',
	      component: () => import(/*webpackChunkName:"Index" */'../views/index')
	  },
	  {
	      path: '/Combat',
	      name: 'combat',
	      component: () => import(/*webpackChunkName:"Combat" */'../views/combat')
	  },
	  {
	      path: '/Electronic',
	      name: 'electronic',
	      component: () => import(/*webpackChunkName:"Electronic" */'../views/electronic')
	  },
	  {
	      path: '/International',
	      name: 'international',
	      component: () => import(/*webpackChunkName:"International" */'../views/international')
	  },
	  {
	      path: '/Family',
	      name: 'family',
	      component: () => import(/*webpackChunkName:"Family" */'../views/family')
	  },
	  {
	      path: '/Psychological',
	      name: 'psychological',
	      component: () => import(/*webpackChunkName:"Psychological" */'../views/psychological')
	  },
	  {
	      path: '/Carbon',
	      name: 'carbon',
	      component: () => import(/*webpackChunkName:"Carbon" */'../views/carbon')
	  },
	  {
	      path: '/Media',
	      name: 'media',
	      component: () => import(/*webpackChunkName:"Media" */'../views/media')
	  },
	  {
	      path: '/Human',
	      name: 'human',
	      component: () => import(/*webpackChunkName:"Human" */'../views/human')
	  },
	  {
	      path: '/Health',
	      name: 'health',
	      component: () => import(/*webpackChunkName:"Health" */'../views/health')
	  },
	  {
	      path: '/Sciences',
	      name: 'sciences',
	      component: () => import(/*webpackChunkName:"Sciences" */'../views/sciences')
	  },
	  {
	      path: '/Teenagers',
	      name: 'teenagers',
	      component: () => import(/*webpackChunkName:"Teenagers" */'../views/teenagers')
	  },
	  {
	      path: '/Marriage',
	      name: 'marriage',
	      component: () => import(/*webpackChunkName:"Marriage" */'../views/marriage')
	  },
	  {
	      path: '/Community',
	      name: 'community',
	      component: () => import(/*webpackChunkName:"Community" */'../views/community')
	  },
	  {
	      path: '/Appraiser',
	      name: 'appraiser',
	      component: () => import(/*webpackChunkName:"Appraiser" */'../views/appraiser')
	  },
	  {
	      path: '/Dietitian',
	      name: 'dietitian',
	      component: () => import(/*webpackChunkName:"Dietitian" */'../views/dietitian')
	  },
	  {
	      path: '/Skin',
	      name: 'skin',
	      component: () => import(/*webpackChunkName:"Skin" */'../views/skin')
	  },
	  {
	      path: '/Internet',
	      name: 'internet',
	      component: () => import(/*webpackChunkName:"Internet" */'../views/internet')
	  },
	  {
	      path: '/Business',
	      name: 'business',
	      component: () => import(/*webpackChunkName:"Business" */'../views/business')
	  },
	  {
	      path: '/About',
	      name: 'about',
	  	 component: () => import(/*webpackChunkName:"About" */'../views/about')
	      // component:  import('@/views/about')
	  },
	  {
	      path: '/Notice',
	      name: 'notice',
	  	 component: () => import(/*webpackChunkName:"Notice" */'../views/notice')
	      // component:  import('@/views/about')
	  },
	  
	  {
	      path: '/Camp',
	      name: 'camp',
	      component: () => import(/*webpackChunkName:"Camp" */'../views/camp')
	  },
	  {
	      path: '/Skills',
	      name: 'skills',
	      component: () => import(/*webpackChunkName:"Skills" */'../views/skills')
	  },
	  {
	      path: '/Latest',
	      name: 'latest',
	      component: () => import(/*webpackChunkName:"Latest" */'../views/latest')
	  }
    
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
